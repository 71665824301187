.ethiopian {
    /** Default Color **/
    --ion-color-primary: #629150 ;
    /** Text **/

    /** Primary Color**/
    --primary-title-color: #000000;
    --primary-subTitle-color: #000000;
    --primary-desc-color: #000000;

    --ion-color-danger: #eb445a;
    --ion-color-warning: #ffc409;
    --ion-color-success: #478f3b;
    --ion-color-dark: #000000;
    --ion-color-light: #ffffff;
    --ion-color-grey: #7b7b7b;

    /** Button **/
    --primary-button-bg-color: #629150 ;
    --primary-button-active-color: #ff8841;
    --primary-button-text-color: #ffffff;
    --primary-button-border-radius: 10px;
    --primary-button-box-shadow: none;

    /** Primary Border Color **/
    --primary-border-button-bg-color: #ffffff;
    --primary-border-button-active-color: #e0e0e0;
    --primary-border-button-text-color: #629150 ;
    --primary-border-button-border-size: 1px;
    --primary-border-button-border-color: #629150 ;
    --primary-border-button-border-radius: 5px;
    --primary-border-button-box-shadow: none;

    /** secondary Button Color**/
    --secondary-button-bg-color: #629150 ;
    --secondary-button-active-color: #ff8841;
    --secondary-button-text-color: #ffffff;
    --secondary-button-border-radius: 10px;
    --secondary-button-box-shadow: none;

    /** Tertiary Button Color**/
    --tertiary-button-bg-color: #f5f5f5;
    --tertiary-button-text-color: #000000;
    --tertiary-button-border-color: #f5f5f5;

    /**  Button Color**/
    --white-button-bg-color: #ffffff;
    --white-button-text-color: #629150 ;
    --white-button-active-color: #e0e0e0;

    /** Clear Button Color **/
    --clear-button-bg-color: transparent;
    --clear-button-text-color: #000000;

    /** Special Button **/
    --special-button-text-color: #000000;
    --special-button-active-color: #000000;

    /** Card **/

    /** Card Color**/
    --card-white-bg-color: #ffffff;
    --card-grey-bg-color: #f7f7f7;
    --card-secondary-bg-color: #ffe9de;
    --card-title-text-color: #000000;
    --card-subTitle-text-color: #000000;
    --card-desc-text-color: #ea4435;
    --card-danger-text-color: red;
    --card-grey-text-color: #b0b0b0;
    --card-special-text-color: #629150 ;
    --card-primary-text-color: #629150 ;

    --card-primary-border-color: #629150 ;
    --card-secondary-border-color: #629150 ;

    /** Tabs **/

    /** Primary Tabs Color**/
    --primary-tabs-bg-color: #629150 ;
    --primary-tabs-text-color: #000000;
    --primary-tabs-activeTab-color: #629150 ;

    /** Label **/

    /** Primary Label Color**/
    --primary-label-bg-color: #fafafa;
    --primary-label-text-color: #000000;

    /** Header **/

    /** Header Text Color**/
    --header-primary-bg-color: #629150 ;
    --header-secondary-bg-color: #ffffff;
    --header-text-color: #ffffff;
    --header-logo-border-color: #629150 ;

    /** Action Sheet **/
    --action-sheet-bg-color: #f0f0f0;
    --action-sheet-text-color: #000000;
    --action-sheet-active-color: #629150 ;

    /** Checkbox **/
    --checkbox-bg-color: #629150 ;
    --checkbox-text-color: #000000;
    --checkbox-border-color: #000000;
    --checkbox-border-color-checked: #629150 ;

    /** Icon Colors **/

    --icon-primary-color: #629150 ;
    --icon-seconday-color: #629150 ;
    --icon-danger-color: red;
    --icon-special-color: #629150 ;

    /** Item Color **/

    --item-text-color: #000000;

    /** Accordion Color **/
    --accordion-white-bg-color: #ffffff;
    --accordion-grey-bg-color: #f7f7f7;

    --accordion-title-text-color: #000000;
    --accordion-subTitle-text-color: #000000;
    --accordion-desc-text-color: #ea4435;
    --accordion-danger-text-color: red;
    --accordion-grey-text-color: #696969;
    --accordion-special-text-color: #1b4b96;

    /** Icon **/
    --logo-height: 45px;
    --logo-width: auto;
    --blur-background: #f9fff8;
}