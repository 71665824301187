.spta {
    /** Default Color **/
    --ion-color-primary: #2f4e8a;
    /** Text **/

    /** Primary Color**/
    --primary-title-color: #2f4e8a;
    --primary-subTitle-color: #2f4e8a;
    --primary-desc-color: #000000;

    --ion-color-danger: #eb445a;
    --ion-color-warning: #ffc409;
    --ion-color-success: #478f3b;
    --ion-color-dark: #000000;
    --ion-color-light: #ffffff;
    --ion-color-grey: #7b7b7b;

    /** Button **/
    --primary-button-bg-color: #2f4e8a;
    --primary-button-active-color: #2f4e8a;
    --primary-button-text-color: #ffffff;
    --primary-button-border-radius: 10px;
    --primary-button-box-shadow: none;

    /** Primary Border Color **/
    --primary-border-button-bg-color: #ffffff;
    --primary-border-button-active-color: #e0e0e0;
    --primary-border-button-text-color: #2f4e8a;
    --primary-border-button-border-size: 1px;
    --primary-border-button-border-color: #2f4e8a;
    --primary-border-button-border-radius: 5px;
    --primary-border-button-box-shadow: none;

    /** secondary Button Color**/
    --secondary-button-bg-color: #b71b48;
    --secondary-button-active-color: #b71b48;
    --secondary-button-text-color: #ffffff;
    --secondary-button-border-radius: 10px;
    --secondary-button-box-shadow: none;

    /** Tertiary Button Color**/
    --tertiary-button-bg-color: #f5f5f5;
    --tertiary-button-text-color: #000000;
    --tertiary-button-border-color: #f5f5f5;

    /**  Button Color**/
    --white-button-bg-color: #ffffff;
    --white-button-text-color: #000000;
    --white-button-active-color: #e0e0e0;

    /** Clear Button Color **/
    --clear-button-bg-color: transparent;
    --clear-button-text-color: #000000;

    /** Special Button **/
    --special-button-text-color: #000000;
    --special-button-active-color: #000000;

    /** Card **/

    /** Card Color**/
    --card-white-bg-color: #ffffff;
    --card-grey-bg-color: #f7f7f7;
    --card-secondary-bg-color: #fbf2f2;

    --card-title-text-color: #000000;
    --card-subTitle-text-color: #000000;
    --card-desc-text-color: #e62f39;
    --card-danger-text-color: red;
    --card-grey-text-color: #b0b0b0;
    --card-special-text-color: #1a509e;
    --card-primary-text-color: #2f4e8a;

    --card-primary-border-color: #2f4e8a;
    --card-secondary-border-color: #b71b48;

    /** Tabs **/

    /** Primary Tabs Color**/
    --primary-tabs-bg-color: #2f4e8a;
    --primary-tabs-text-color: #000000;
    --primary-tabs-activeTab-color: #2f4e8a;

    /** Label **/

    /** Primary Label Color**/
    --primary-label-bg-color: #fafafa;
    --primary-label-text-color: #000000;

    /** Header **/

    /** Header Text Color**/
    --header-primary-bg-color: #2f4e8a;
    --header-secondary-bg-color: #ffffff;
    --header-text-color: #ffffff;
    --header-logo-border-color: #2f4e8a;

    /** Action Sheet **/
    --action-sheet-bg-color: #f0f0f0;
    --action-sheet-text-color: #000000;
    --action-sheet-active-color: #e62f39;

    /** Checkbox **/
    --checkbox-bg-color: #e62f39;
    --checkbox-text-color: #000000;
    --checkbox-border-color: #000000;
    --checkbox-border-color-checked: #e62f39;

    /** Icon Colors **/

    --icon-primary-color: #2f4e8a;
    --icon-seconday-color: #e62f39;
    --icon-danger-color: red;
    --icon-special-color: #1a509e;

    /** Item Color **/

    --item-text-color: #000000;

    /** Accordion Color **/
    --accordion-white-bg-color: #ffffff;
    --accordion-grey-bg-color: #f7f7f7;

    --accordion-title-text-color: #000000;
    --accordion-subTitle-text-color: #000000;
    --accordion-desc-text-color: #e62f39;
    --accordion-danger-text-color: red;
    --accordion-grey-text-color: #696969;
    --accordion-special-text-color: #1a509e;

    /** Icon **/
    --logo-height: 30px;
    --logo-width: 66px;

    /** Discount Badge **/
    /** Primary Discount Badge **/
    --primary-badge-bg-color: #2f4e8a;
    --primary-badge-text-color: #ffffff;

    /** breadcrumb **/
    --primary-breadcrumb-text-color: #2f4e8a;
    --secondary-breadcrumb-text-color: #666276;
    --separator-breadcrumb-color: #666276;

    --blur-background: #F9FFF8;
}