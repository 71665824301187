.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem;
  font-size: 14px;
  

  &__item {
    display: flex;
    align-items: center;
    color:  var(--separator-breadcrumb-color);
    transition: all 0.2s ease;

    &:last-child {
      .breadcrumb__link {
        color: var(--primary-breadcrumb-text-color);
        font-weight: 500;

        &:hover {
          text-decoration: none;
          cursor: default;
        }
      }
      .breadcrumb__separator {
        display: none;
      }
    }
  }

  &__link {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__separator {
    margin: 0 0.5rem;
    color: var(--secondary-breadcrumb-text-color);
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0.75rem;

    &__separator {
      margin: 0 0.25rem;
    }
  }

  &--truncate {
    .breadcrumb__link {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;

      @media (max-width: 768px) {
        max-width: 150px;
      }
    }
  }
}
