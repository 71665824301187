.productGridItem {
  ion-card {
    white-space: break-spaces;
    height: 255px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

    .discountBadge {
      position: absolute;
      left: 10px;
      background-color: var(--primary-badge-bg-color);
      color: var(--primary-badge-text-color);
      padding: 2px 6px;
      font-size: 10px;
      font-weight: 500;
      min-height: 48px;
      max-width: 70px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 60%, 0% 100%);
      z-index: 1;
      padding-top: 10px;
    }

    ion-img {
      padding: 8px;
      height: 140px;
    }

    .title {
      min-height: 33px;
    }

    .itemDetail {
      @media (max-width: 415px) {
        min-height: 130px;
      }
      .productListProductPrice {
        text-decoration: line-through;
      }

      .price {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: var(--ion-color-step-850, #262626);
      }
    }
  }
}

.productImg {
  height: 150px;
  padding: 8px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}
