.dealCategory {
  ion-item {
    --inner-padding-end: 5px;

    ion-button {
      text-decoration: underline;
    }
  }

  ion-card {
    height: 260px;
    width: 184px;
    white-space: break-spaces;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

    .discountBadge {
        position: absolute;
        left: 10px;
        background-color:  var(--primary-badge-bg-color);
        color: var(--primary-badge-text-color);
        padding: 2px 6px;
        font-size: 10px;
        font-weight: 500;
        min-height: 48px;
        max-width: 70px;
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 60%, 0% 100%);
        z-index: 1;
        padding-top: 10px;
      }

    .imgContainer {
      height: 140px;

      ion-img {
        padding: 8px;
        height: 130px;
      }
    }

    ion-card-title{
      width: 176px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    ion-card-subtitle {
      margin-top: 2px;
      min-height: 25px;
    }

    .contentContainer {
      height: 120px;

        .productSectionProductPrice {
            text-decoration: line-through;
          }
    }
  }
}

.productImg{
  height: 150px;
  padding: 8px;
  img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}