.product {
  display: inline-block;

  ion-card {
    position: relative;
    width: 180px;
    min-height: 245px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

    .discountBadge {
      position: absolute;
      left: 10px;
      background-color: var(--primary-badge-bg-color);
      color: var(--primary-badge-text-color);
      padding: 2px 6px;
      font-size: 10px;
      font-weight: 500;
      min-height: 48px;
      max-width: 70px;
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 60%, 0% 100%);
      z-index: 1;
      padding-top: 10px;
    }

    ion-img {
      padding: 8px;
      height: 140px;
    }

    .productSectionProductPrice {
      text-decoration: line-through;
    }

    ion-text {
      span {
        display: block;
      }
    }
  }
}

.productSectionCardContainer {
  height: 265px;

  .productSectionImgContainer {
    height: 150px;
  }

  .productSectionContentContainer {
    ion-card-header {
      height: 40px;
    }
    // background-color: #f7f7f7;
    height: 140px;
  }
}

.productSubtitle {
  height: 25px;
}
.productImg {
  height: 150px;
  padding: 8px;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 50%;
  }
}
